/* Proxima Nova */

/* normal */

/* 100 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNovaT-Thin.eot');
	src: url('../fonts/Proxima_Nova/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNovaT-Thin.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNovaT-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
/* 200 */

/* 300 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-Light.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-Light.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
/* 400 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-Regular.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-Regular.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
/* 500 */

/* 600 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-Semibold.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-Semibold.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
/* 700 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-Bold.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-Bold.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
/* 800 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-Extrabld.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-Extrabld.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-Extrabld.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
/* 900 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-Black.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-Black.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
/* italic */

/* 100 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-ThinIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-ThinIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-ThinIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-ThinIt.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}
/* 200 */

/* 300 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-LightIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-LightIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-LightIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

/* 400 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-RegularIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-RegularIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-RegularIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-RegularIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
/* 500 */

/* 600 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-SemiboldIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-SemiboldIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-SemiboldIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}
/* 700 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-BoldIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-BoldIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-BoldIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
/* 800 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-ExtrabldIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-ExtrabldIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-ExtrabldIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-ExtrabldIt.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}
/* 900 */
@font-face {
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima_Nova/ProximaNova-BlackIt.eot');
	src: url('../fonts/Proxima_Nova/ProximaNova-BlackIt.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Proxima_Nova/ProximaNova-BlackIt.woff') format('woff'),
	url('../fonts/Proxima_Nova/ProximaNova-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
html {
	font-size: 16px;
}
html, body {
	overflow-x: hidden;
}
body {
	font-size: 1rem;
	font-weight: 400;
	line-height: normal;
	color: #fff;
	background-color: #111;
	font-family: 'DINPro', sans-serif;
}
body.open {
	overflow: hidden !important;
}
body.open .link-logo {
	-webkit-transform: translateY(-100px);
	    -ms-transform: translateY(-100px);
	        transform: translateY(-100px);
}
body.open nav {
	height: 100%;
	overflow: scroll !important;
	position: fixed;
}
body.open .nav-panel {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
body.open .nav-icon span:nth-child(1) {
	top: 18px;
	width: 0;
	left: 50%;
}
body.open .nav-icon span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
}
body.open .nav-icon span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
body.open .nav-icon span:nth-child(4) {
	top: 18px;
	width: 0;
	left: 50%;
}
html.open {
	overflow: hidden !important;
}
@media (min-width: 568px) and (max-width: 767px) {
	.container {
		max-width: 100% !important;
	}
}
@media (max-width: 567px) {
	.hidden-xs {
		display: none;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.hidden-sm {
		display: none;
	}
}
::-moz-selection {
	background-color: #e31e2c;
	color: #fff;
}
::selection {
	background-color: #e31e2c;
	color: #fff;
}
h1, h2, h3, h4, h5, h6, p, ul li, ol li, blockquote, cite {
	font-family: 'DINPro', sans-serif;
	color: #fff;
}
h1 {
	font-size: 4.75em;
	font-weight: 700;
	line-height: .63;
	margin-bottom: 2.6875rem;
	letter-spacing: 1.3px;
}
@media (max-width: 767px) {
	h1 {
		font-size: 40px;
		line-height: 1.4;
		letter-spacing: .7px;
		margin-bottom: .25rem;
	}
}
.h1-catalog {
	font-size: 54px;
	font-weight: 900;
	line-height: 1.83;
	letter-spacing: 1.8px;
	text-shadow: 0 6px 50px rgba(0,0,0,.37);
	margin-bottom: .875rem;
}
@media (max-width: 767px) {
	.h1-catalog {
		font-size: 40px;
		line-height: 2.48;
		letter-spacing: 1px;
	}
}
h2 {
	font-size: 2.5em;
	font-weight: 700;
	line-height: 1.55;
	margin-bottom: 1.6875rem;
	margin-left: 1.875rem;
	letter-spacing: 4.2px;
	text-transform: uppercase;
}
@media (max-width: 767px) {
	h2 {
		margin-left: 0rem;
		font-size: 28px;
		line-height: 2.21;
		letter-spacing: 2.9px;
		position: relative;
		padding-bottom: .75rem;
		margin-bottom: 1.25rem;
	}
	h2:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 68px;
		height: 2px;
		background-color: #e31e2c;
	}
}
.h2-line {
	position: relative;
	margin-left: 0rem;
	margin-bottom: 2.5rem;
	padding-bottom: 2.3125rem;
	z-index: 11;
}
.h2-line:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 68px;
	height: 2px;
	background-color: #e31e2c;
}
@media (max-width: 767px) {
	.h2-line {
		padding-bottom: .75rem;
		margin-bottom: 1.25rem;
	}
}
.h2-left {
	margin-left: 0rem;
	margin-bottom: 2.1875rem;
}
.h2-margin-small {
	margin-bottom: 1rem;
}
.h2-modal {
	font-size: 48px;
	line-height: 1.29;
	letter-spacing: 5px;
	text-transform: none;
	margin-top: 11px;
	margin-bottom: 1.375rem;
}
@media (max-width: 767px) {
	.h2-modal {
		font-size: 28px;
	}
}
.h2-catalog {
	font-size: 40px;
	font-weight: 900;
	line-height: 2.48;
	letter-spacing: 1.3px;
	text-shadow: 0 6px 50px rgba(0,0,0,.37);
	margin-left: 0rem;
	margin-bottom: 1.125rem;
}
@media (max-width: 767px) {
	.h2-catalog {
		display: none;
	}
}
.h2-404 {
	text-transform: none;
	letter-spacing: 1.3px;
	margin-bottom: 1.1875rem;
}
@media (max-width: 767px) {
	.h2-404 {
		font-size: 44px;
		line-height: 2.25;
		letter-spacing: 1.1px;
		padding-bottom: 0;
	}
	.h2-404:after {
		display: none;
	}
}
h3 {
	font-size: 1.375em;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 0rem;
	color: #e31e2c;
}
h4 {
	font-size: 1.375em;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 2.75rem;
	letter-spacing: 1.1px;
}
@media (max-width: 767px) {
	h4 {
		font-size: 18px;
		letter-spacing: .8px;
	}
}
h5 {
	font-size: .875em;
	font-weight: 700;
	line-height: 1.43;
	margin-bottom: .625rem;
}
h6 {
	font-size: .875em;
	font-weight: 700;
	line-height: 1.5;
	margin-bottom: 3.125rem;
}
p {
	font-size: .875em;
	font-weight: 400;
	line-height: 1.75;
	margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
	p {
		line-height: 1.5;
		font-size: 1em;
	}
}
.p-lead {
	font-size: 1em;
	font-weight: 400;
	line-height: 1.25;
	margin-bottom: 4.0625rem;
}
@media (max-width: 567px) {
	.p-lead {
		margin-bottom: 15rem;
	}
}
.p-mouse {
	text-align: center;
	color: #888;
	margin-bottom: 0rem;
	font-size: 1.125em;
	letter-spacing: -.55px;
}
.p-404 {
	font-size: 1.125em;
	line-height: normal;
	margin-bottom: 4.375rem;
}
@media (max-width: 767px) {
	.p-404 {
		margin-bottom: 3.25rem;
	}
}
hr {
	border: 0;
	height: .0625rem;
	background-color: #000;
	margin: .9375rem 0;
}
.breadcrumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: .9375rem;
}
.breadcrumbs li {
	position: relative;
	padding-right: .9375rem;
	margin-right: .9375rem;
}
.breadcrumbs li:after {
	right: 0;
	top: 0;
	height: 100%;
	width: .0625rem;
	background-color: #000;
	-webkit-transform: rotate(25deg);
	    -ms-transform: rotate(25deg);
	        transform: rotate(25deg);
}
.breadcrumbs li:last-child:after {
	display: none;
}
.breadcrumbs li a {
	display: inline-block;
	color: #000;
}
.breadcrumbs li a:hover {
	color: #add8e6;
}
.breadcrumbs li a.active {
	cursor: default;
	pointer-events: none;
	color: #00f;
}
footer {
	margin-bottom: .75rem;
}
.footer-copyright {
	font-size: 12px;
	line-height: 2;
	color: #6c6c6c;
}
@media (max-width: 767px) {
	.footer-copyright {
		font-size: 10px;
		line-height: 2.4;
		margin-bottom: .5rem;
	}
}
nav {
	background-color: #111;
	padding: 1.5625rem 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
}
nav .link-logo {
	-webkit-transform: translateY(316px) scale(2.4) translateX(16px);
	    -ms-transform: translateY(316px) scale(2.4) translateX(16px);
	        transform: translateY(316px) scale(2.4) translateX(16px);
	-webkit-transition: .4s all ease;
	     -o-transition: .4s all ease;
	        transition: .4s all ease;
}
@media (max-width: 767px) {
	nav .link-logo {
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
}
@media (max-width: 767px) {
	nav {
		padding: 0;
		background-color: transparent;
	}
}
nav.scroll {
	position: fixed;
	padding: .9375rem 0;
}
nav.scroll .link-logo {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
@media (min-width: 768px) and (max-width: 991px) {
	nav.scroll .link-logo {
		padding-top: 12px;
	}
}
@media (max-width: 767px) {
	nav.scroll {
		padding: 0;
		background-color: #111;
	}
	nav.scroll .link-logo {
		max-width: 40px;
	}
}
nav.nav-catalog {
	background-color: transparent;
}
nav.nav-catalog .link-logo {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
@media (min-width: 768px) and (max-width: 991px) {
	nav.nav-catalog .link-logo {
		padding-top: 12px;
	}
}
nav.nav-catalog.scroll {
	background-color: #111;
}
.menu-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 9.125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.menu-nav {
		margin-right: .9375rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.menu-nav {
		margin-right: 0rem;
	}
}
@media (max-width: 767px) {
	.menu-nav {
		margin: 0 0 50px;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		padding-left: .9375rem;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.menu-nav {
		margin-top: 1.875rem;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 50%;
		    flex: 1 1 50%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		    flex-direction: row;
	}
}
.menu-nav li {
	margin: 0 1.25rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.menu-nav li {
		margin: 0 .625rem;
	}
}
@media (max-width: 767px) {
	.menu-nav li {
		margin: 0;
	}
}
@media (max-width: 567px) {
	.menu-nav li {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.menu-nav li {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 45%;
		    flex: 1 1 45%;
		max-width: 45%;
		width: 45%;
	}
}
.menu-nav li a {
	display: block;
	color: #888;
	font-size: .875em;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.menu-nav li a {
		white-space: nowrap;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.menu-nav li a {
		font-size: .75em;
		white-space: nowrap;
	}
}
@media (max-width: 767px) {
	.menu-nav li a {
		font-size: 20px;
		font-weight: 500;
		line-height: 2;
		letter-spacing: 1px;
		color: #fff;
	}
}
.menu-nav li a:hover, .menu-nav li a.active {
	color: #e31e2c;
}
.nav-panel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: .6875rem 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.nav-panel {
		background-color: #000;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		-webkit-transform: translateY(-150vh);
		    -ms-transform: translateY(-150vh);
		        transform: translateY(-150vh);
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		padding: 0;
		height: 100%;
	}
}
.menu-lang {
	position: relative;
	height: 40px;
	margin-right: .9375rem;
	margin-left: 1.8125rem;
	width: 68px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.menu-lang {
		margin-right: 0rem;
		margin-left: .625rem;
	}
}
@media (max-width: 767px) {
	.menu-lang {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
		margin: 0;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding-left: .9375rem;
		padding-top: .625rem;
	}
}
.menu-lang__link {
	position: relative;
	display: block;
	padding: 10px 20px 11px;
	font-size: 12px;
	font-weight: 300;
	text-align: center;
	color: #fff;
	border: solid 2px transparent;
}
.menu-lang__link img {
	display: none;
	margin-right: .75rem;
}
@media (max-width: 767px) {
	.menu-lang__link {
		text-align: left;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: #52575a;
		font-weight: 500;
		font-size: 16px;
		padding: 0;
		border: none;
		margin-right: 1.375rem;
	}
	.menu-lang__link img {
		display: block;
	}
}
.menu-lang__link:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #fff;
	top: calc(50% - 2px);
	right: 6px;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.menu-lang__link:after {
		display: none;
	}
}
.menu-lang__link:hover {
	color: #fff;
}
@media (max-width: 767px) {
	.menu-lang__link:hover {
		color: #52575a;
	}
}
.menu-lang ul {
	position: absolute;
	left: 0;
	right: 0;
	top: 37px;
	background-color: #111;
	border: solid 2px #393e41;
	visibility: hidden;
	z-index: -1;
	opacity: 0;
	-webkit-transform: translateY(20px);
	    -ms-transform: translateY(20px);
	        transform: translateY(20px);
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.menu-lang ul {
		z-index: 300;
		position: static;
		opacity: 1;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
		visibility: visible;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		border: none;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: transparent;
	}
}
.menu-lang ul li {
	text-align: center;
	margin: 10px 0;
}
@media (max-width: 767px) {
	.menu-lang ul li {
		margin: 0 22px;
	}
}
.menu-lang ul li a {
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	display: inline-block;
}
@media (max-width: 767px) {
	.menu-lang ul li a {
		font-weight: 500;
		font-size: 16px;
	}
}
.menu-lang ul li a:hover {
	color: #e31e2c;
}
.menu-lang:hover .menu-lang__link {
	border-color: #393e41;
}
.menu-lang:hover .menu-lang__link:after {
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.menu-lang:hover ul {
	z-index: 100;
	opacity: 1;
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
	visibility: visible;
}
.menu-order {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 332px;
	    flex: 1 1 332px;
	max-width: 332px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (min-width: 768px) and (max-width: 991px) {
	.menu-order {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 260px;
		    flex: 1 1 260px;
		max-width: 260px;
	}
}
@media (max-width: 767px) {
	.menu-order {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		padding-left: .9375rem;
		padding-right: .9375rem;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.menu-order {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
.menu-order li {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 166px;
	    flex: 1 1 166px;
	max-width: 166px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.menu-order li {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 130px;
		    flex: 1 1 130px;
		max-width: 130px;
	}
}
@media (max-width: 767px) {
	.menu-order li {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		text-align: center;
	}
	.menu-order li:last-child {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.menu-order li {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 50%;
		    flex: 1 1 50%;
		max-width: 50%;
	}
}
.menu-order li .btn {
	position: relative;
	top: -1px;
	width: 100%;
	height: 40px;
	font-weight: 700;
}
@media (max-width: 767px) {
	.menu-order li .btn {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: .9px;
		max-width: 100%;
		top: 0;
		height: 50px;
		padding-top: .625rem;
		margin-bottom: 0rem;
	}
}
.nav-mobile {
	display: none;
	padding-right: 0rem;
}
@media (max-width: 767px) {
	.nav-mobile {
		display: block;
	}
}
.nav-icon-wrapper {
	background-color: transparent;
	width: 54px;
	height: 50px;
	float: right;
	padding: 1.0625rem .9375rem;
	position: relative;
	z-index: 900;
}
.nav-icon {
	width: 1.5rem;
	height: 1.25rem;
	position: relative;
	cursor: pointer;
	-webkit-transform: rotate(0deg);
	    -ms-transform: rotate(0deg);
	        transform: rotate(0deg);
	float: right;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-icon span {
	display: block;
	position: absolute;
	height: .125rem;
	width: 100%;
	background-color: #fff;
	border-radius: .125rem;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	    -ms-transform: rotate(0deg);
	        transform: rotate(0deg);
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-icon span:nth-child(1) {
	top: 0;
}
.nav-icon span:nth-child(2), .nav-icon span:nth-child(3) {
	top: 7px;
}
.nav-icon span:nth-child(4) {
	top: 14px;
}
.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0 auto;
}
.pagination li {
	margin: 0 .9375rem;
}
.pagination li a {
	display: inline-block;
	color: #000;
	font-size: 1.125em;
}
.pagination li a:hover {
	color: #add8e6;
}
.pagination li .arrow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}
.pagination li .active {
	color: #00f;
	cursor: default;
	pointer-events: none;
}
#preloader-load {
	display: block;
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #111 url('../img/icons/grid.svg') center center no-repeat;
	background-size: 3.75rem;
}
section {
	padding-top: 11rem;
	padding-bottom: 3.125rem;
}
@media (max-width: 767px) {
	section {
		padding-top: 1.625rem;
	}
}
.section-first {
	padding-top: 18.5625rem;
	padding-bottom: 3.125rem;
	min-height: 648px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 0;
}
@media (max-width: 767px) {
	.section-first {
		padding-top: 7.375rem;
		display: block;
		min-height: 1px;
	}
}
@media (max-width: 567px) {
	.section-first {
		padding-bottom: 120px;
	}
}
.section-history {
	padding-top: 21.9375rem;
	padding-bottom: 41.5625rem;
	background: url('../img/illus/screen3.png') center 85px no-repeat;
}
@media (max-width: 767px) {
	.section-history {
		padding-top: 6.1875rem;
		padding-bottom: 30.375rem;
		background: url('../img/illus/screen3.png') 58% 187% no-repeat;
		background-size: 1140px auto;
	}
}
.section-clients {
	background: url('../img/illus/screen5.png') center -52px no-repeat;
	padding-top: 14.5625rem;
	padding-bottom: 24.4375rem;
}
@media (max-width: 767px) {
	.section-clients {
		padding-top: 6.1875rem;
		padding-bottom: 19.4375rem;
		background: url('../img/illus/screen5.png') center 178px no-repeat;
		background-size: 1030px;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.section-clients {
		background: url('../img/illus/screen5.png') center 78px no-repeat;
		background-size: 1030px;
	}
}
.section-contacts {
	padding-bottom: 4.625rem;
}
@media (max-width: 767px) {
	.section-contacts {
		padding-top: 5.5625rem;
		padding-bottom: 2.5rem;
	}
}
.section-animals {
	padding-top: 13rem;
	padding-bottom: 3.375rem;
}
@media (max-width: 767px) {
	.section-animals {
		padding-top: 7.0625rem;
		padding-bottom: 7.5625rem;
	}
}
.section-meat {
	padding-top: 7.5625rem;
	padding-bottom: 3.5rem;
}
@media (max-width: 767px) {
	.section-meat {
		padding-top: 4.8125rem;
	}
}
.section-catalog-first {
	padding-top: 114px;
	background: url('../img/illus/catalog.png') center center no-repeat;
}
@media (max-width: 767px) {
	.section-catalog-first {
		background: none;
		padding-bottom: 0;
		padding-top: 113px;
	}
}
.section-catalog {
	padding-top: 106px;
	padding-bottom: 4px;
}
@media (max-width: 767px) {
	.section-catalog {
		padding-top: 0rem;
	}
}
.section-404 {
	min-height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 767px) {
	.section-404 {
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}
button {
	cursor: pointer;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	background-color: transparent;
	border: solid 2px #e31e2c;
	height: 61px;
	width: calc(100% + 4px);
	position: absolute;
	bottom: -2px;
	right: -2px;
}
button .ready-text {
	font-size: 18px;
	font-weight: bold;
	display: none;
}
button svg {
	position: absolute;
	right: 15px;
	bottom: 15px;
}
@media (max-width: 767px) {
	button svg {
		right: 14px;
		bottom: 11px;
	}
}
button svg g {
	fill: #e31e2c;
}
button:focus {
	outline: none;
}
button.ready {
	width: 458px;
	background-color: #e31e2c;
	color: #fff;
	z-index: 999;
}
@media (min-width: 768px) and (max-width: 991px) {
	button.ready {
		width: 510px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	button.ready {
		width: 450px;
	}
}
@media (max-width: 767px) {
	button.ready {
		width: 100%;
	}
}
button.ready .ready-text {
	display: block;
}
button.ready svg g {
	fill: #fff;
}
button.ready:hover {
	background-color: #99141d;
	border-color: #99141d;
}
@media (max-width: 767px) {
	button {
		width: 100%;
		position: relative;
		bottom: 0;
		right: 0;
		top: -6px;
		background-color: #e31e2c;
		color: #fff;
		height: 50px;
		padding-bottom: .1875rem;
	}
	button .ready-text {
		display: block;
	}
	button svg g {
		fill: #fff;
	}
}
.btn {
	display: block;
	max-width: 166px;
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: .8px;
	color: #e31e2c;
	border: solid 2px #e31e2c;
	padding: 7px 0 10px;
	position: relative;
	z-index: 10;
}
.btn:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	background-color: #e31e2c;
	z-index: -1;
}
.btn:hover {
	color: #fff;
}
.btn:hover:after {
	height: 100%;
}
.btn-big {
	max-width: 263px;
	border: none;
	background-color: #e31e2c;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: .9px;
	padding: 21px 0 23px;
}
.btn-big:after {
	background-color: #99141d;
}
.btn-big:hover {
	color: #fff;
}
@media (max-width: 567px) {
	.btn-big {
		max-width: 100%;
		padding: 11px 0 14px;
	}
}
.btn-catalog {
	position: absolute;
	bottom: 20px;
	right: 2px;
}
@media (max-width: 767px) {
	.btn-catalog {
		position: relative;
		bottom: 4px;
		right: 0;
		padding-bottom: 15px;
		padding-top: 12px;
	}
}
.btn-catalog-big {
	bottom: 0;
	right: 0;
	max-width: 360px;
	padding-bottom: 19px;
	padding-top: 17px;
	z-index: 300;
}
.btn-pdf {
	max-width: 360px;
	margin: 50px auto;
	padding-top: 18px;
	padding-bottom: 21px;
}
@media (max-width: 767px) {
	.btn-pdf {
		margin-top: .9375rem;
	}
}
.btn-404 {
	max-width: 100%;
	padding-top: 16px;
	padding-bottom: 21px;
}
@media (max-width: 767px) {
	.btn-404 {
		margin-bottom: 52px;
	}
}
.img-responsive {
	width: 100%;
}
.img-first {
	position: absolute;
	left: -472px;
	top: -132px;
	z-index: -1;
}
@media (max-width: 567px) {
	.img-first {
		max-width: 425px;
		top: -267px;
		left: -82px;
		right: -15px;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.img-first {
		max-width: 500px;
		left: -200px;
		top: -30px;
	}
}
.img-border-first {
	position: absolute;
	top: 8px;
	z-index: -1;
	left: 15px;
}
@media (max-width: 767px) {
	.img-border-first {
		display: none;
	}
}
.img-border-second {
	position: absolute;
	top: 8px;
	z-index: -1;
	left: 15px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.img-border-second {
		max-width: 600px;
	}
}
@media (max-width: 767px) {
	.img-border-second {
		display: none;
	}
}
.img-border-third {
	position: absolute;
	top: 0;
	z-index: -1;
	left: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.img-border-third {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-border-third {
		display: none;
	}
}
@media (max-width: 767px) {
	.img-border-third {
		display: none;
	}
}
.img-screen2 {
	position: relative;
	top: -71px;
}
@media (max-width: 767px) {
	.img-screen2 {
		max-width: 100%;
		position: static;
		margin-top: 1.375rem;
	}
}
.img-screen4 {
	margin-top: 84px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.img-screen4 {
		max-width: 500px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-screen4 {
		max-width: 100%;
	}
}
@media (max-width: 767px) {
	.img-screen4 {
		max-width: 100%;
		margin-top: 1rem;
	}
}
.img-404 {
	float: right;
	max-width: 100%;
}
a {
	text-decoration: none;
	font-family: 'DINPro', sans-serif;
	color: #00f;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
a svg g {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
a:hover {
	text-decoration: none;
	color: #add8e6;
}
a:focus {
	text-decoration: none;
	outline: none;
}
.link-logo {
	display: block;
	max-width: 3.3125rem;
}
@media (max-width: 767px) {
	.link-logo {
		max-width: 65px;
		padding-top: .8125rem;
		padding-bottom: .8125rem;
	}
}
.link-logo img {
	width: 100%;
}
.link-phone {
	font-size: 14px;
	color: #fff;
}
@media (max-width: 767px) {
	.link-phone {
		text-decoration: underline;
		font-size: 16px;
		line-height: 1.88;
	}
}
.link-phone:hover {
	color: #e31e2c;
}
.link-mouse {
	display: block;
	margin: 34px auto 0;
	width: 25px;
}
.link-mouse svg {
	-webkit-animation: 2s scroll infinite both;
	        animation: 2s scroll infinite both;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.link-mouse {
		display: none;
	}
}
.link-trimming {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-weight: bold;
	color: #fff;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 16px;
	font-size: .875em;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.link-trimming {
		margin-bottom: 5px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.link-trimming {
		margin-bottom: 5px;
	}
}
@media (max-width: 767px) {
	.link-trimming {
		margin: 0 3px;
	}
}
.link-trimming svg {
	margin-right: .75rem;
	width: 50px;
	height: auto;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.link-trimming svg {
		width: 40px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.link-trimming svg {
		width: 40px;
	}
}
.link-trimming svg g {
	fill: #393e41;
}
.link-trimming:hover {
	color: #fff;
}
.link-trimming:hover svg g {
	fill: #e31e2c;
}
.link-volta {
	font-size: .75em;
	float: right;
	text-align: right;
	display: block;
	line-height: 2;
	color: #6c6c6c;
}
.link-volta svg {
	margin-left: .625rem;
}
.link-volta svg path, .link-volta svg polygon {
	fill: #6c6c6c;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.link-volta {
		line-height: 1.33;
	}
}
.link-volta:hover {
	color: #e31e2c;
}
.link-volta:hover svg path, .link-volta:hover svg polygon {
	fill: #e31e2c;
}
@-webkit-keyframes scroll {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(32px);
		        transform: translateY(32px);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}
@keyframes scroll {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(32px);
		        transform: translateY(32px);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}
ul li {
	list-style: none;
}
ul li ul {
	margin-right: .9375rem;
	margin-left: 1.25rem;
}
ul, ol {
	padding: 0;
	margin: 0;
}
ul li, ol li {
	font-family: 'DINPro', sans-serif;
}
ol {
	margin-left: 1.25rem;
}
ol li ol {
	margin-top: .9375rem;
}
.ul-setificate {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 1rem;
	margin-left: -8px;
	margin-right: -12px;
}
@media (max-width: 767px) {
	.ul-setificate {
		margin-top: 1.5625rem;
	}
}
.ul-setificate li {
	text-align: center;
	margin-bottom: 2.5rem;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 159px;
	    flex: 1 1 159px;
	max-width: 159px;
}
@media (max-width: 767px) {
	.ul-setificate li {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 50%;
		    flex: 1 1 50%;
		max-width: 50%;
		margin-bottom: 1.3125rem;
	}
	.ul-setificate li:last-child img {
		max-width: 134px;
	}
}
.ul-setificate li img {
	display: inline-block;
	margin-bottom: 1.375rem;
}
@media (max-width: 767px) {
	.ul-setificate li img {
		max-width: 111px;
		margin-bottom: 1.625rem;
	}
}
.ul-setificate li a {
	color: #5b5b5b;
	position: relative;
	display: inline-block;
	letter-spacing: .8px;
	font-weight: bold;
	padding-bottom: .5rem;
}
@media (max-width: 767px) {
	.ul-setificate li a {
		padding-bottom: .6875rem;
	}
}
.ul-setificate li a:after {
	content: '';
	display: block;
	position: absolute;
	height: 2px;
	background-color: #5b5b5b;
	bottom: 0;
	left: 0;
	right: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.ul-setificate li a:hover {
	color: #e31e2c;
}
.ul-setificate li a:hover:after {
	background-color: #e31e2c;
}
.ul-contacts {
	padding-top: 1.0625rem;
	padding-left: 2.75rem;
}
@media (max-width: 767px) {
	.ul-contacts {
		padding-left: 0;
		padding-top: 39px;
	}
}
.ul-contacts li {
	font-size: 1em;
	line-height: 1.71;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: .6875rem;
	position: relative;
}
@media (max-width: 767px) {
	.ul-contacts li {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
}
.ul-contacts li .btn {
	position: absolute;
	right: 0;
	text-decoration: none;
	font-size: 1em;
	color: #e31e2c;
	font-weight: 700;
	margin: 0;
	bottom: 27px;
	padding-bottom: .6875rem;
}
@media (max-width: 767px) {
	.ul-contacts li .btn {
		bottom: 0;
		position: relative;
		margin-left: 3.75rem;
		height: 42px;
	}
}
.ul-contacts li .btn:hover {
	color: #fff;
}
.ul-contacts li p {
	line-height: 1.43;
	margin-bottom: 1.8125rem;
}
@media (max-width: 767px) {
	.ul-contacts li p {
		font-size: .875em;
		margin-bottom: .9375rem;
	}
}
.ul-contacts li a {
	margin-top: .25rem;
	color: #fff;
	text-decoration: underline;
	line-height: normal;
	font-size: .875em;
}
.ul-contacts li a:hover {
	color: #e31e2c;
}
.ul-contacts__address {
	max-width: 200px;
}
@media (max-width: 767px) {
	.ul-contacts__address {
		max-width: 185px;
	}
}
.ul-contacts__image {
	width: 30px;
	margin-right: 1.5rem;
}
@media (max-width: 767px) {
	.ul-contacts__image {
		margin-right: 1.875rem;
	}
}
.ul-contacts__li {
	padding-top: 1.9375rem;
}
@media (max-width: 767px) {
	.ul-contacts__li {
		margin-bottom: 41px !important;
	}
}
#map {
	width: 100%;
	height: 26.5625rem;
	margin-bottom: 2rem;
	background-color: #111;
}
@media (max-width: 767px) {
	#map {
		height: 335px;
		margin-bottom: .9375rem;
	}
}
.fancybox-slide > div {
	width: 100%;
	max-width: 1170px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.fancybox-slide > div {
		max-width: 960px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.fancybox-slide > div {
		max-width: 720px;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.fancybox-slide > div {
		max-width: 540px;
	}
}
@media (max-width: 567px) {
	.fancybox-slide > div {
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.fancybox-close-small {
	padding: 0;
	width: 35px;
	height: 35px;
	right: 12px;
	top: 12px;
}
.fancybox-close-small svg {
	display: none;
}
.fancybox-close-small:after, .fancybox-close-small:before {
	content: '';
	display: block;
	position: absolute;
	width: 35px;
	height: 2px;
	background-color: #888;
	top: 50%;
	left: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.fancybox-close-small:after {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
.fancybox-close-small:before {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.fancybox-close-small:hover {
	opacity: .6;
}
.fancybox-close-small:focus {
	outline: none;
}
.fancybox-content {
	background-color: #111;
	padding: 6.625rem 0 9.625rem;
	overflow-x: hidden;
}
.fancybox-content .container {
	padding: 0;
}
@media (min-width: 1200px) {
	.fancybox-content button.ready {
		width: 470px;
		bottom: -3px;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.fancybox-content button.ready {
		width: 382px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.fancybox-content button.ready {
		width: 595px;
	}
}
.slick-loading .slick-list {
	background: #fff url('../img/slick/ajax-loader.gif') center center no-repeat;
}
@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/slick/slick.eot');
	src: url('../fonts/slick/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick/slick.woff') format('woff'), url('../fonts/slick/slick.ttf') format('truetype'), url('../fonts/slick/slick.svg#slick') format('svg');
}
.slider-container {
	position: relative;
}
.slide {
	width: 100%;
	overflow: hidden;
	height: 347px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 767px) {
	.slide {
		height: 220px;
	}
}
.slide img {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	    flex: 1 1 auto;
	height: 100%;
}
.arrow {
	border: solid 2px #888;
	width: 94px;
	height: 68px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	bottom: -97px;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	z-index: 300;
}
@media (max-width: 767px) {
	.arrow {
		width: 88px;
		height: 50px;
		bottom: -14px;
		border-color: #e31e2c;
	}
}
.arrow svg g {
	fill: #888;
}
@media (max-width: 767px) {
	.arrow svg g {
		fill: #e31e2c;
	}
}
.arrow:hover {
	border-color: #e31e2c;
	z-index: 350;
}
.arrow:hover svg g {
	fill: #e31e2c;
}
.arrow.right {
	right: 0;
}
.arrow.left {
	right: 98px;
}
.arrow.small {
	bottom: auto;
	top: -2px;
	right: -68px;
	width: 68px;
	height: 68px;
}
@media (max-width: 767px) {
	.arrow.small {
		top: -63px;
		right: 0;
		width: 88px;
		height: 50px;
	}
}
.arrow.small.right {
	top: 64px;
}
@media (max-width: 767px) {
	.arrow.small.right {
		top: -63px;
		right: -2px;
	}
}
@media (max-width: 767px) {
	.arrow.small.left {
		right: 97px;
	}
}
.slider-counter {
	font-size: 18px;
	color: #888;
}
.slider-one {
	position: relative;
	top: -71px;
	height: 347px;
}
@media (max-width: 767px) {
	.slider-one {
		top: 0;
		margin-top: 1.375rem;
		height: 220px;
	}
}
@media (max-width: 567px) {
	.slider-one {
		height: 287px;
	}
}
.slider-counter-one {
	position: absolute;
	bottom: -33px;
	right: 231px;
}
@media (max-width: 767px) {
	.slider-counter-one {
		bottom: -20px;
	}
}
@media (max-width: 567px) {
	.slider-counter-one {
		right: auto;
		left: -3px;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.slider-counter-one {
		left: auto;
		right: 220px;
	}
}
@media (max-width: 767px) {
	.slider-advantage {
		margin-top: -8px;
	}
}
.slider-advantage .arrow {
	z-index: 300;
	bottom: 0;
}
@media (max-width: 767px) {
	.slider-advantage .arrow {
		bottom: auto;
		top: 30px;
	}
}
.slider-advantage .arrow.left {
	right: -124px;
}
@media (max-width: 767px) {
	.slider-advantage .arrow.left {
		right: 99px;
	}
}
.slider-advantage .arrow.right {
	right: -222px;
}
@media (max-width: 767px) {
	.slider-advantage .arrow.right {
		right: 0;
	}
}
.slider-advantage-double {
	margin-right: -27px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-advantage-double {
		margin-right: 0;
	}
}
@media (max-width: 767px) {
	.slider-advantage-double {
		display: none;
	}
}
.slider-counter-advantage {
	position: absolute;
	bottom: -6px;
	right: -294px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-counter-advantage {
		right: -270px;
	}
}
@media (max-width: 767px) {
	.slider-counter-advantage {
		bottom: auto;
		top: -1px;
		right: -1px;
	}
}
.slider-container-cow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	left: -67px;
	top: -85px;
	width: calc(100% + 65px);
	height: 336px;
}
@media (max-width: 767px) {
	.slider-container-cow {
		left: 0;
		top: -2px;
		width: 100%;
		height: 294px;
	}
}
.slider-cow {
	border: solid 2px #393e41;
	width: calc(100% - 66px);
}
@media (max-width: 767px) {
	.slider-cow {
		width: 100%;
	}
}
.slider-cow-arrows {
	width: 66px;
	border-top: solid 2px #393e41;
	border-bottom: solid 2px #393e41;
	border-right: solid 2px #393e41;
}
@media (max-width: 767px) {
	.slider-cow-arrows {
		display: none;
	}
}
.slide-cow {
	padding-top: 2.75rem;
	padding-left: 3.125rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-cow {
		padding-top: 1.5rem;
		padding-left: 1.875rem;
	}
}
@media (max-width: 767px) {
	.slide-cow {
		padding-top: 26px;
		padding-left: 23px;
		padding-right: 23px;
	}
}
.slide-cow h4 {
	max-width: 250px;
}
@media (max-width: 767px) {
	.slide-cow h4 {
		max-width: 200px;
	}
}
.slide-cow img {
	max-width: 262px;
	width: 100%;
	display: inline-block;
}
@media (max-width: 767px) {
	.slide-cow img {
		max-width: 244px;
	}
}
.slider-counter-cow {
	display: none;
}
@media (max-width: 767px) {
	.slider-counter-cow {
		display: block;
		position: absolute;
		top: -91px;
		right: -1px;
	}
}
.slider-cow-big {
	position: relative;
	border-left: 0;
	border-right: 0;
}
.slider-cow-big:after, .slider-cow-big:before {
	z-index: 200;
	content: '';
	display: block;
	position: absolute;
	height: 100%;
	width: 2px;
	background-color: #393e41;
	top: 0;
	bottom: 0;
}
.slider-cow-big:before {
	left: 0;
}
.slider-cow-big:after {
	right: 0;
}
.slider-container-cow-big {
	top: 0;
	height: 488px;
	left: -64px;
}
.slider-container-cow-big .arrow {
	right: -66px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-container-cow-big .slide-cow img {
		max-width: 90%;
	}
}
.slide-cow-big {
	padding-left: 0;
	padding-top: 0;
	height: 488px;
	position: relative;
}
.slide-cow-big__top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 767px) {
	.slide-cow-big__top {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
}
.slide-cow-big__top__heading {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 50%;
	    flex: 1 1 50%;
	max-width: 50%;
	padding-left: 27px;
	padding-top: 39px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-cow-big__top__heading {
		padding-left: .9375rem;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 48%;
		    flex: 1 1 48%;
		max-width: 48%;
	}
}
@media (max-width: 767px) {
	.slide-cow-big__top__heading {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
	}
}
.slide-cow-big__top__heading h4 {
	font-size: 28px;
	letter-spacing: .9px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-cow-big__top__heading h4 {
		font-size: 22px;
	}
}
.slide-cow-big__top__image {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 50%;
	    flex: 1 1 50%;
	max-width: 50%;
	padding-top: 33px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-cow-big__top__image {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 52%;
		    flex: 1 1 52%;
		max-width: 52%;
	}
	.slide-cow-big__top__image img {
		max-width: 90%;
	}
}
@media (max-width: 767px) {
	.slide-cow-big__top__image {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
	}
}
.slide-cow-big__description {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	border-top: solid 2px #393e41;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 100%;
	    flex: 1 1 100%;
	max-width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 3.6875rem;
}
.slide-cow-big__description__col {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 33.33333%;
	    flex: 1 1 33.33333%;
	max-width: 33.333333%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.slide-cow-big__description__col_col {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 100%;
	    flex: 1 1 100%;
	max-width: 100%;
	border-bottom: solid 2px #393e41;
	padding-left: .9375rem;
	color: #8b8b8b;
	padding-bottom: .6875rem;
	padding-top: .5625rem;
	height: 42px;
}
.slide-cow-big__description__col_col b {
	color: #fff;
	font-size: 14px;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-cow-big__description__col_col b {
		font-size: 12px;
	}
}
@media (max-width: 767px) {
	.slide-cow-big__description__col_col b {
		font-size: 1em;
	}
}
.cow-col-1, .cow-col-4 {
	border-left: solid 1px #393e41;
	border-right: solid 2px #393e41;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 33%;
	    flex: 1 1 33%;
	max-width: 33%;
}
.cow-col-2, .cow-col-5 {
	border-right: solid 2px #393e41;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 27%;
	    flex: 1 1 27%;
	max-width: 27%;
}
.cow-col-3, .cow-col-6 {
	border-right: solid 1px #393e41;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 40%;
	    flex: 1 1 40%;
	max-width: 40%;
}
.cow-block {
	height: auto;
	margin-bottom: 3.125rem;
}
@media (max-width: 767px) {
	.cow-block {
		padding-right: 0rem;
		margin-bottom: 4.3125rem;
	}
}
.cow-block .slide-cow-big__top__heading h4 {
	font-size: 1.375em;
	letter-spacing: .9px;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top__heading h4 {
		font-size: 18px;
		font-weight: 500;
	}
}
.cow-block .slide-cow-big__top__heading {
	padding-left: 41px;
	padding-top: 33px;
	position: relative;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top__heading {
		padding-left: 19px;
		padding-top: 24px;
	}
}
.cow-block .slide-cow-big__top__heading img {
	position: absolute;
	bottom: 41px;
	left: 42px;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top__heading img {
		max-width: 63px;
		bottom: auto;
		left: auto;
		right: 18px;
		top: 29px;
	}
}
.cow-block .slide-cow-big__top__image {
	padding-top: 54px;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top__image {
		padding-top: 35px;
		text-align: right;
		padding-right: 12px;
		padding-bottom: 54px;
	}
}
.cow-block .slide-cow-big__top__image img {
	max-width: 90%;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top__image img {
		max-width: 232px;
	}
}
.cow-block .slide-cow-big__description {
	position: static;
	border-left: solid 2px #25282a;
	border-right: solid 2px #25282a;
	border-color: #25282a;
	padding-bottom: 0rem;
}
.cow-block .slide-cow-big__description__col {
	border-color: #25282a;
}
.cow-block .slide-cow-big__description__col_col {
	border-color: #25282a;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__description__col_col {
		min-height: 45px;
		height: auto;
		padding-left: .625rem;
	}
}
.cow-block .slide-cow-big__top {
	height: 266px;
	border-left: solid 2px #25282a;
	border-right: solid 2px #25282a;
	border-top: solid 2px #25282a;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top {
		height: auto;
	}
}
.cow-block .cow-col-3, .cow-block .cow-col-6 {
	border-right: none;
}
.cow-block .cow-col-1, .cow-block .cow-col-4 {
	border-left: none;
}
@media (max-width: 767px) {
	.cow-block .cow-col-1, .cow-block .cow-col-2, .cow-block .cow-col-3 {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		border: none;
	}
	.cow-block .cow-col-1 .slide-cow-big__description__col_col:nth-child(2), .cow-block .cow-col-2 .slide-cow-big__description__col_col:nth-child(2), .cow-block .cow-col-3 .slide-cow-big__description__col_col:nth-child(2) {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 46%;
		    flex: 1 1 46%;
		max-width: 46%;
		border-right: solid 2px #25282a;
	}
	.cow-block .cow-col-1 .slide-cow-big__description__col_col:nth-child(3), .cow-block .cow-col-2 .slide-cow-big__description__col_col:nth-child(3), .cow-block .cow-col-3 .slide-cow-big__description__col_col:nth-child(3) {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 54%;
		    flex: 1 1 54%;
		max-width: 54%;
	}
	.cow-block .cow-col-3 .slide-cow-big__description__col_col:nth-child(2) {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		border-right: none;
	}
	.cow-block .cow-col-3 .slide-cow-big__description__col_col:nth-child(3) {
		display: none;
	}
	.cow-block .cow-col-4 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 54%;
		    flex: 1 1 54%;
		max-width: 54%;
		border-right: none;
	}
	.cow-block .cow-col-5 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 46%;
		    flex: 1 1 46%;
		max-width: 46%;
	}
	.cow-block .cow-col-6 {
		max-width: 100%;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
	}
}
@media (max-width: 767px) {
	.catalog-row {
		display: none;
	}
}
.slider-container-advantages:after, .slider-container-advantages:before {
	content: '';
	display: block;
	position: absolute;
	width: 2px;
	top: 0;
	bottom: 0;
	background-color: #393e41;
}
@media (max-width: 767px) {
	.slider-container-advantages:after, .slider-container-advantages:before {
		display: none;
	}
}
.slider-container-advantages:after {
	left: 0;
}
.slider-container-advantages:before {
	right: 0;
}
input {
	-webkit-appearance: none;
	width: 100%;
	padding: .875rem 1.75rem 1.375rem;
	border: solid 2px #393e41;
	position: relative;
	z-index: 1;
	color: #fff;
	background-color: transparent;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	input {
		padding-left: 1.375rem;
		padding-right: 1.375rem;
	}
}
input:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0 50px #000 !important;
	-webkit-text-fill-color: #999 !important;
}
input:focus {
	z-index: 10;
	outline: none;
	border-color: #979797;
}
input:-moz-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	input:-moz-placeholder {
		font-size: 1em;
	}
}
input::-moz-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	input::-moz-placeholder {
		font-size: 1em;
	}
}
input:-ms-input-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	input:-ms-input-placeholder {
		font-size: 1em;
	}
}
input::-webkit-input-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	input::-webkit-input-placeholder {
		font-size: 1em;
	}
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}
input[type=reset] {
	border: 0;
	cursor: pointer;
}
.input2 {
	margin-top: -2px;
}
.input3 {
	margin-top: -2px;
}
.input-group {
	position: relative;
}
.input-group__error {
	position: absolute;
	z-index: 20;
	right: 0;
	top: 100%;
	background-color: #e31e2c;
	font-size: 12px;
	font-weight: 300;
	width: 219px;
	height: 25px;
	text-align: center;
	padding-top: .25rem;
	padding-bottom: .375rem;
	opacity: 0;
	visibility: hidden;
}
@media (max-width: 767px) {
	.input-group__error {
		width: 195px;
	}
}
.input-group input.error, .input-group textarea.error {
	border-color: #e31e2c;
	z-index: 20;
}
.input-group input.error + .input-group__error, .input-group textarea.error + .input-group__error {
	opacity: 1;
	visibility: visible;
}
textarea {
	-webkit-appearance: none;
	resize: none;
	border-radius: 0;
	width: 100%;
	background-color: transparent;
	height: 11.1875rem;
	position: relative;
	color: #fff;
	padding: 1.1875rem 1.75rem 1.4375rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	border: solid 2px #393e41;
}
@media (max-width: 767px) {
	textarea {
		padding-left: 1.375rem;
		padding-right: 1.375rem;
		height: 130px;
	}
}
textarea:focus {
	z-index: 10;
	outline: none;
	border-color: #979797;
}
textarea:-moz-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	textarea:-moz-placeholder {
		font-size: 1em;
	}
}
textarea::-moz-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	textarea::-moz-placeholder {
		font-size: 1em;
	}
}
textarea:-ms-input-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	textarea:-ms-input-placeholder {
		font-size: 1em;
	}
}
textarea::-webkit-input-placeholder {
	font-size: 14px;
	font-weight: bold;
	color: #7c7c7c;
}
@media (max-width: 767px) {
	textarea::-webkit-input-placeholder {
		font-size: 1em;
	}
}
select {
	width: 100%;
	background-color: #fff;
	border: .0625rem solid #ccc;
	padding: .75rem .9375rem .8125rem;
	border-radius: 0;
	display: block;
	margin-bottom: 1.25rem;
	-webkit-appearance: none;
	   -moz-appearance: none;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
select:focus {
	outline: none;
	border: .0625rem solid #00f;
}
.select-group {
	position: relative;
	margin-bottom: 1.25rem;
}
.select-group:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #000;
	top: calc(50% - 2px);
	right: .9375rem;
}
.select-group-noarrow:after {
	display: none;
}
.form-send {
	padding-top: 21px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}
@media (max-width: 767px) {
	.form-send {
		padding-top: 1.5rem;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
}
.form-send__left {
	max-width: calc(100% - 66px);
	-webkit-box-flex: 1;
	-ms-flex: 1 1 calc(100% - 66px);
	    flex: 1 1 calc(100% - 66px);
}
@media (max-width: 767px) {
	.form-send__left {
		max-width: 100%;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
	}
}
.form-send__right {
	max-width: 66px;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 66px;
	    flex: 1 1 66px;
	border-top: solid 2px #393e41;
	border-right: solid 2px #393e41;
	border-bottom: solid 2px #393e41;
	position: relative;
	margin-bottom: .25rem;
}
@media (max-width: 767px) {
	.form-send__right {
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		border: 0;
	}
}
.form-send__success {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #e31e2c;
	text-align: center;
	padding-top: 5.1875rem;
	opacity: 0;
	z-index: -1;
	visibility: hidden;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.form-send__success p {
	line-height: normal;
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 1.8125rem;
}
.form-send.success .form-send__success {
	opacity: 1;
	z-index: 1500;
	visibility: visible;
}
.border-block {
	padding: 8.4375rem 0 0 4.25rem;
}
@media (max-width: 767px) {
	.border-block {
		padding: 0;
	}
}
.border-block2 {
	padding: 8.4375rem 6.25rem 0 8.1875rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.border-block2 {
		padding-top: 6.25rem;
		padding-right: 3.125rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.border-block2 {
		padding-left: 5.625rem;
		padding-right: .625rem;
		padding-top: 6.25rem;
	}
}
@media (max-width: 767px) {
	.border-block2 {
		padding: 0;
	}
}
.advantage {
	border: solid 2px #393e41;
	height: 397px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
@media (max-width: 767px) {
	.advantage {
		border: none;
		height: auto;
	}
}
.advantage__icon {
	max-width: 97px;
	height: 95px;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 97px;
	    flex: 1 1 97px;
	border-right: solid 2px #393e41;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 767px) {
	.advantage__icon {
		max-width: 82px;
		height: 90px;
		border-top: solid 2px #393e41;
		border-left: solid 2px #393e41;
	}
}
.advantage__heading {
	height: 95px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 3.125rem;
}
@media (max-width: 767px) {
	.advantage__heading {
		padding-left: 1.625rem;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		height: 90px;
		border-top: solid 2px #393e41;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 100%;
		    flex: 1 1 100%;
		max-width: 100%;
		border-left: solid 2px #393e41;
		border-right: solid 2px #393e41;
	}
}
.advantage__description {
	border-top: solid 2px #393e41;
	padding: 2.4375rem 2.5rem 0 3.1875rem;
	position: relative;
	height: calc(100% - 95px);
}
@media (min-width: 568px) and (max-width: 767px) {
	.advantage__description {
		border-bottom: solid 2px #393e41;
	}
}
@media (max-width: 767px) {
	.advantage__description {
		padding: 1.25rem 1.375rem 4.4375rem 1.4375rem;
		border-left: solid 2px #393e41;
		border-right: solid 2px #393e41;
	}
}
.advantage-icon {
	width: 133px;
	cursor: pointer;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.advantage-icon {
		width: 100px;
	}
}
.advantage-icon__image {
	height: 133px;
	margin-bottom: .6875rem;
	border: solid 2px #393e41;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.advantage-icon__image {
		height: 100px;
	}
}
.advantage-icon__text {
	font-size: 18px;
	font-weight: 500;
	color: #393e41;
	text-align: center;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.advantage-icon:hover .advantage-icon__image {
	border-color: #fff;
}
.advantage-icon:hover .advantage-icon__image svg path {
	fill: #fff;
}
.advantage-icon:hover .advantage-icon__image svg polyline, .advantage-icon:hover .advantage-icon__image svg polygon, .advantage-icon:hover .advantage-icon__image svg rect {
	stroke: #fff;
}
.advantage-icon:hover .advantage-icon__text {
	color: #fff;
}
.btn-advantage {
	position: absolute;
	bottom: 0;
	right: 0;
}
.cow {
	border: solid 2px #393e41;
	width: calc(100% - 35px);
	height: 336px;
	margin-top: 22px;
	padding-left: 5.9375rem;
	padding-top: 2.375rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.cow {
		padding-left: 2.75rem;
	}
}
@media (max-width: 767px) {
	.cow {
		margin-top: 1.5625rem;
		padding-top: .5rem;
		padding-left: .4375rem;
		padding-right: .4375rem;
		width: 81px;
		height: 91px;
	}
}
.cow__image {
	margin-bottom: 1.625rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.cow__image {
		margin-bottom: 1.25rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.cow__image {
		margin-bottom: .625rem;
	}
}
.cow__image svg {
	max-width: 263px;
	height: auto;
}
@media (max-width: 767px) {
	.cow__image {
		margin-bottom: 0rem;
	}
	.cow__image svg {
		max-width: 63px;
		height: auto;
	}
}
.cow__trimming {
	padding-left: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: center;
	align-content: center;
	margin-left: -85px;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.cow__trimming {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.cow__trimming {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
@media (max-width: 767px) {
	.cow__trimming {
		margin-left: 0rem;
	}
}
@media (max-width: 767px) {
	.cow__trimming {
		padding-left: 0rem;
		font-size: 0;
		text-align: center;
		padding-top: .1875rem;
	}
	.cow__trimming svg {
		width: 25px;
		height: auto;
		margin-right: 0rem;
	}
}
@media (max-width: 767px) and (max-width: 767px) {
	.cow__trimming svg {
		width: 16px;
	}
}
.cow-svg__polygon {
	fill: #393e41;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	cursor: pointer;
}
.cow-svg__polygon:hover, .cow-svg__polygon.active {
	fill: #e31e2c;
}
.cow-svg__polygon:hover svg polygon, .cow-svg__polygon.active svg polygon, .cow-svg__polygon:hover svg g, .cow-svg__polygon.active svg g {
	fill: #e31e2c;
}
.cow-big {
	margin-top: 0rem;
	padding-left: 67px;
	padding-top: 53px;
	height: 488px;
	width: calc(100% - 32px);
	border-right: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
	.cow-big {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.cow-big .cow__image {
	margin-bottom: 50px;
}
.cow-big .cow__image svg {
	max-width: 391px;
	width: 100%;
	height: auto;
}
.cow-big .cow__trimming {
	text-align: center;
	padding-left: 0;
	margin-left: -39px;
}
.mouse-catalog {
	margin-top: 4.625rem;
}
.mouse-catalog .link-mouse {
	margin-top: 8px;
}
.cow-block .slide-cow-big__top__heading img {
	max-width: 123px;
}
@media (max-width: 767px) {
	.cow-block .slide-cow-big__top__heading img {
		max-width: 63px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-cow img {
		margin-right: .625rem;
		max-width: 225px;
		height: auto;
	}
}
.ul-screen {
	position: fixed;
	top: 43%;
	right: calc(50vw - (1140px / 2));
	z-index: 999;
}
@media (min-width: 768px) and (max-width: 991px) {
	.ul-screen {
		display: none;
	}
}
@media (max-width: 767px) {
	.ul-screen {
		display: none;
	}
}
.ul-screen li {
	margin-bottom: .375rem;
}
.ul-screen li a {
	font-size: 0;
	display: block;
	color: #fff;
	position: relative;
	width: 20px;
	text-align: center;
	font-family: 'Circe', sans-serif;
	padding-bottom: .3125rem;
}
.ul-screen li a:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	height: 1px;
	background-color: #5e5e5e;
	left: 5px;
	right: 5px;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.ul-screen li a.active {
	font-size: .75em;
}
.ul-screen li a.active:after {
	background-color: #e31e2c;
	left: 0;
	right: 0;
}
.ul-screen li a:hover:after {
	background-color: #e31e2c;
	left: 0;
	right: 0;
}
.history-back {
	position: relative;
	z-index: 10;
}
.history-back:after {
	content: '';
	display: block;
	position: absolute;
	background-color: #111;
	-webkit-box-shadow: 0 0 83px 0 #111;
	        box-shadow: 0 0 83px 0 #111;
	top: -96px;
	left: -30px;
	right: -30px;
	bottom: -60px;
	z-index: -1;
}